.warranty-banner-container {
  /* margin-inline: -4rem; */
  margin-inline: calc(0px - var(--padding-inline));
}

.warranty-banner-container img {
  width: 100%;
  /* max-width: 100vw; */
  /* max-height: 50vh; */
}

@media only screen and (max-width: 600px) {
  .warranty-banner-container {
    margin-inline: -1.5rem;
  }
}
