.cta-franchise-program-container {
  display: flex;
  margin-top: 8%;
  border-radius: 8px;
  background: url(/public/images/cta-blue-asset-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.cta-franchise-program-container .franchise-program-des-container {
  flex-basis: 1100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 9% 11% 9% 6%;
}

.cta-franchise-program-container .franchise-program-img-container {
  flex-basis: 500px;
  /* background: url(/public/images/india-map-cta.svg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.franchise-program-img .map-img {
  /* margin-top: 25%; */
  width: 100%;
}

.franchise-program-des-container .franchise-program-title {
  font-weight: 700;
  color: #fff;
  font-size: 45px;
}

.franchise-program-des-container .franchise-program-subtitle {
  color: #fff;
  font-size: 25px;
  line-height: 2.5rem;
  padding-right: 17%;
}

.trct-img {
  width: 100%;
  height: 100%;
  padding: 6%;
  margin-top: 4%;
  margin-bottom: -10%;
}

@media screen and (min-width: 750px) and (max-width: 1500px) {
  .cta-franchise-program-container .franchise-program-des-container {
    gap: 12px;
  }

  .franchise-program-des-container .franchise-program-title {
    font-size: 32px;
  }

  .franchise-program-des-container .franchise-program-subtitle {
    font-size: 18px;
    line-height: 1.8rem;
  }

  .cta-franchise-program-container .franchise-program-img-container {
    background-size: cover;
  }

  .trct-img {
    padding: 7%;
  }
}

@media only screen and (max-width: 600px) {
  .cta-franchise-program-container {
    flex-direction: column;
    height: auto;
  }

  .cta-franchise-program-container .franchise-program-des-container {
    /* width: 100%; */
    flex-basis: 240px;
    gap: 15px;
    padding: 10% 5% 0 5%;
    margin-bottom: -20%;
    z-index: 1;
  }

  .franchise-program-des-container .franchise-program-title {
    font-size: 30px;
  }

  .franchise-program-des-container .franchise-program-subtitle {
    font-size: 14px;
    line-height: 1.4rem;
    padding-right: 5%;
  }

  .cta-franchise-program-container .franchise-program-img-container {
    flex-basis: 370px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .trct-img {
    padding: 2%;
    margin-top: 59%;
    margin-bottom: 0;
  }
  .franchise-program-img .map-img {
    margin-top: 25%;
    /* width: 100%; */
  }
  .franchise-program-des-container .franchise-program-title {
    font-size: 24px;
  }
  .cta-franchise-program-container {
    margin-top: 4rem;
  }
}
