.BuyWarrantyBanner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  border-radius: 8px;
  background-color: #ec2726;
  color: white;
  overflow: hidden;
  min-height: 400px;
  position: relative;
}

.BuyWarrantyBanner-container .text-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-basis: 50%;
}

.BuyWarrantyBanner-container .text-container > p {
  color: white;
}

.BuyWarrantyBanner-container .buy-warranty-image {
  bottom: -4rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 115%;
}

@media screen and (max-width: 1024px) {
  .BuyWarrantyBanner-container .buy-warranty-image {
    height: 90%;
  }
}

@media screen and (max-width: 900px) {
  .BuyWarrantyBanner-container .buy-warranty-image {
    opacity: 0.8;
    position: absolute;
  }
}

@media screen and (max-width: 600px) {
  .BuyWarrantyBanner-container {
    flex-direction: column-reverse;
    padding: 0;
    gap: 0;
  }

  .BuyWarrantyBanner-container .buy-warranty-image {
    position: relative;
    transform: translateY(-20%);
    width: calc(100%);
  }

  .BuyWarrantyBanner-container .text-container {
    padding: 1rem 2rem 2rem;
  }
}
