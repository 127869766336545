.franchise-container {
  display: flex;
  padding: 5rem 0;
}

.franchise-container .franchise-des-container {
  flex-basis: 900px;
  padding-right: 3rem;
  flex-grow: 1;
}

.franchise-des-container .franchise-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* gap:1rem; */
}

.franchise-des-container .franchise-title > p {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
  padding-bottom: 1rem;
}

.franchise-title .franchise-img {
  width: 100%;
  border-radius: 8px;
}

.franchise-form-container {
  flex-basis: 900px;
  padding-left: 3rem;
  flex-shrink: 1;
  display: flex;
  /* align-items: center; */
}

.franchise-title > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
  margin-bottom: 1rem;
}

.franchise-form-container .field-container .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 2.6px;
}

@media only screen and (max-width: 1024px) {
  .franchise-form-container .form-container {
    width: 100%;
  }

  .franchise-container {
    flex-wrap: wrap;
  }

  .franchise-form-container {
    padding: 3rem 0;
  }

  .franchise-container .franchise-des-container {
    padding-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .franchise-container .franchise-des-container {
    padding-right: 0;
  }

  .franchise-des-container .franchise-title > p {
    font-size: 24px;
  }

  .franchise-container {
    padding: 3rem 0;
    margin-bottom: -6rem;
  }

  .franchise-form-container .form-container .column-two {
    flex-wrap: wrap;
  }
}
