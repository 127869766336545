/* .upload-wrapper {} */

.uploaddoc-icon {
  font-size: 48px;
}

.action-text {
  color: var(--clr-primary);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.uploaddoc-btn {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
  border: none;
  font-family: inherit;
  cursor: pointer;
}

.upload-wrapper .drop-area {
  cursor: pointer;
  min-height: 235px;
  padding: 16px;
  border: 2px dashed #d6dce3;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.upload-wrapper .drop-area.error {
  border: 2px dashed #d32f2faa;
}

.uploadedImgContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 24px;
  color: hsl(0, 0%, 33%);
  font-size: 14px;
}

.cancel-file-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
}

.uploadedImgContainer > div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.uploadedImgContainer img {
  box-shadow: 0px 0px 8px #00285233;
}

.upload-wrapper .infoText,
.upload-wrapper .infoText p {
  color: #676767;
  font-size: 13px;
  line-height: 1.5;
}

.uploaded-img-avatar.MuiAvatar-root {
  background-color: transparent;
  box-shadow: 0px 0px 16px #00000020;
}
