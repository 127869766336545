.about-us-details-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin: 7rem 0;
}

.about-us-details-head-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
  /* padding-bottom: 1rem; */
}

.about-us-details-head-container > p {
  color: var(--clr-primary);
  font-size: 32px;
  font-weight: 400;
}

.about-us-details-des-container {
  flex-basis: 850px;
}
.about-us-details-head-container {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
.about-us-details-description-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-us-details-img-container {
  flex-basis: 900px;
  display: flex;
  justify-content: flex-end;
}

.about-us-details-title-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: inherit;
  border-radius: 8px;
}

@media only screen and (max-width: 1024px) {
  .about-us-details-container {
    flex-wrap: wrap;
    gap: 2rem;
    flex-direction: column-reverse;
  }
  .about-us-details-img-container {
    flex-basis: 500px;
  }
  .about-us-details-des-container {
    flex-basis: auto;
  }
}
