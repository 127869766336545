/***** Add Form *****/

.add-booking-container h1 {
  margin-bottom: 2rem;
}

.add-booking-container label {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 600;
}

.step-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.add-booking-container .form-btn-container {
  margin-top: 1rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.add-booking-container .form-btn-container button {
  min-width: 120px;
}

.step-form-container > * {
  border-radius: 8px;
}

/* .step-form-container .form-column-2 .field-container {} */

.step-form-container .Stepper-Form {
  background-color: var(--bg-blue);
  padding: 3rem 1rem;
  flex-basis: 200px;
  flex-grow: 2;
  max-width: 400px;
  height: fit-content;
}

.step-form-container .booking-form-container {
  flex-basis: 500px;
  flex-grow: 5;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.step-form-container .booking-form-container .booking-seletion {
  background-color: white;
  margin: 1rem 0;
  display: grid;
  gap: 1rem;
}

.step-form-container .booking-form-container .booking-seletion .customer_add,
.step-form-container .booking-form-container .booking-seletion .tractor_details,
.step-form-container .booking-form-container .booking-seletion .service_details,
.step-form-container .booking-form-container .booking-seletion .workshop_details {
  flex-basis: calc(50% - 1rem);
}

.step-form-container .booking-form-container .booking-seletion .customer_add p,
.step-form-container .booking-form-container .booking-seletion .tractor_details p,
.step-form-container .booking-form-container .booking-seletion .service_details p,
.step-form-container .booking-form-container .booking-seletion .workshop_details p {
  /* margin: 0.5rem 0; */
  line-height: 1.25rem;
}

.step-form-container .booking-form-container .booking-seletion .customer_add h3,
.step-form-container .booking-form-container .booking-seletion .tractor_details h3,
.step-form-container .booking-form-container .booking-seletion .service_details h3,
.step-form-container .booking-form-container .booking-seletion .workshop_details h3 {
  margin-bottom: 0.5rem;
}

.step-form-container .booking-form-container .booking-form {
  background-color: white;
  padding: 2rem;
}

.add-booking-container .info-container {
  background-color: var(--bg-blue);
  padding: 1rem 1.5rem;
}

.add-booking-container .info-container h3 {
  font-size: 22px;
  margin-bottom: 16px;
}

.add-booking-container .info-container > div {
  display: flex;
  gap: 16px;
}

.add-booking-container .info-container > div > div > p:nth-child(1) {
  color: var(--clr-gray);
  font-size: 14px;
  margin-bottom: 8px;
}

.add-booking-container .info-container > div > div > p:nth-child(2) {
  color: var(--clr-black);
  font-size: 16px;
}

/* Booking Summary View */

.booking-summary-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  margin: 0 -4rem;
  background-color: #f2f7ff;
  padding: 4rem;
}

.booking-summary-container > * {
  background-color: white;
  border-radius: 3px;
  padding: 24px;
}

.booking-summary-container > .booking-details {
  order: 1;
  grid-row: 1/3;
}

/* .booking-summary-container>.customer-details {
  order: 2;
} */

/* .booking-summary-container>.coupon-details {
  order: 3;
  display: flex;
  gap: 16px;
} */

.booking-summary-container > .price-details {
  order: 2;
  /* grid-column: 2/-1; */
}

.booking-summary-container > .payment-details {
  order: 3;
  /* grid-column: 2/-1; */
}

.booking-summary-container > .button-container {
  order: 4;
  grid-column: 1/-1;
  justify-content: flex-end;
  background-color: transparent;
  padding: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.booking-summary-container button {
  min-width: 120px;
}

.booking-summary-container h2 {
  font-size: 22px;
  font-weight: 600;
}

.booking-summary-container .grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.grid > p {
  color: var(--clr-gray);
}

.grid > div {
  color: var(--clr-black);
  font-weight: 500;
}

.grid > hr {
  grid-column: 1/ -1;
  width: 100%;
  border: 2px solid #f2f7ff;
  margin: 0px;
}

/* .coupon-details h3 {} */

.coupon-details p {
  color: var(--clr-gray);
  font-size: 14px;
}

.coupon-details button {
  margin-top: 24px;
}

.coupon-details .view-link {
  font-size: 12px;
  color: red;
}

.coupon-details .view-link > a {
  text-decoration: underline;
}

.coupon-details .view-link span {
  padding-left: 2px;
}

.price-details .grid {
  grid-template-columns: 1fr auto;
}

.booking-meta {
  padding: 2rem;
  margin-top: 2rem;
  background: #fff;
  box-shadow: 0px 0px 18px 13px rgba(0, 0, 0, 0.03);
  height: fit-content;
}

.booking-seletion p.title {
  color: var(--clr-gray);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.booking-seletion p.details {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.address-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.address-card-container .address-card {
  border-radius: 7px;
  box-shadow: 1px 23px 52px -11px rgba(0, 0, 0, 0.12);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.address-card-container .address-card .image-container img {
  width: 100%;
}

.address-card-container .address-card .address-card-details {
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-card-container .address-card button {
  min-width: 120px;
}

.address-card .tag {
  display: flex;
  background-color: var(--bg-blue);
  gap: 4px;
  padding: 8px 16px;
  width: fit-content;
  font-weight: 600;
}

.address-card .task-list {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;
}

.address-card .task-list .action-text {
  font-size: 14px;
  color: var(--clr-secondary);
  text-decoration: underline;
  grid-column: 1/-1;
  cursor: pointer;
}

.address-card .card-title {
  color: var(--clr-primary);
  font-size: 24px;
  font-weight: 600;
}

.address-card .card-subtitle {
  color: var(--clr-gray);
  font-size: 14px;
}

.address-card .card-value {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 600;
}

.service-selection-container .tractor-model-list-container {
  position: relative;
  top: -4rem;

  width: min(100%, 700px);
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
  box-shadow: 1px 23px 52px -11px rgba(0, 0, 0, 0.12);
  padding: 2rem 2rem 1rem;
}

.service-selection-container .tractor-model-list-container .field-container {
  flex-basis: 200px;
}

.service-selection-container .tractor-model-list-container button {
  flex-basis: 120px;
}

.address-card .card-btn {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.address-card .card-btn span {
  font-size: 22px;
  color: var(--clr-black);
  font-weight: 600;
  margin-left: auto;
  padding: 4px;
}

.address-card .card-btn span.crossed {
  font-size: 18px;
  /* color: var(--clr-gray); */
  color: #c4c4c4;
  font-weight: 600;
  text-decoration: line-through;
  /* @media (max-width: 1440px) {
    display: flex;
    justify-content: end;
  } */
}

.reschedule_dates button.Active {
  background-color: rgba(0, 40, 82, 0.1);
}

.address-details h2 {
  font-size: 30;
  margin-bottom: 4;
}

.address-details > div {
  font-size: 22;
}

.cart.cart-booking {
  margin-left: -4rem;
}

/* Booking get view */

@media only screen and (max-width: 600px) {
  .step-form-container .booking-form-container {
    flex-basis: 500px;
    flex-grow: 5;
    display: grid;
    grid-template-columns: 1fr;
  }

  .booking-summary-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 0 -4rem;
    background-color: #f2f7ff;
    padding: 4rem;
  }

  .address-details h2 {
    font-size: 26;
    margin-bottom: 4;
  }

  .address-details > div {
    font-size: 18;
  }

  .cart.cart-booking {
    margin-left: -1.5rem;
  }
}
