.quality-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 6rem;
}

.quality-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.quality-container > h3 {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.quality-container .quality-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0rem 0rem 2rem;
  gap: 4rem;
}

.quality-card-container .quality-card-icn {
  height: 54px;
}

@media only screen and (max-width: 600px) {
  .quality-container {
    align-items: flex-start;
    margin-top: 2.5rem;
  }

  .quality-container .quality-card-container {
    margin: 0;
    gap: 1.5rem;
    justify-content: flex-start;
    padding-bottom: 2rem;
  }

  .quality-container > p {
    font-size: 24px;
  }

  .quality-card-container .quality-card-icn {
    height: 32px;
  }
}
