.profile-details .personal-details-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}

.personal-details .personal-details-grid .grid-title {
  color: var(--clr-primary);
  font-weight: 500;
}

.personal-details .personal-details-grid p:not(.grid-title) {
  color: black;
  font-weight: 600;
}

.personal-details .icon-edit {
  display: flex;
  background-color: #d4dbe287;
  padding: 8px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}
