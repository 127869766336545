.join-our-franchise-program-container {
  display: flex;
  gap: 4rem;
  margin: 7% 0;
}

.join-our-franchise-head-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
  padding-bottom: 1rem;
}

.join-our-franchise-head-container > p {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.join-our-franchise-des-container > p {
  color: var(--clr-gray);
  font-size: 14px;
  line-height: 1.4rem;
}

.join-our-franchise-program-des .join-our-franchise-head-container {
  padding-bottom: 2rem;
}

.join-our-franchise-program-des .join-our-franchise-des-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
}

.join-our-franchise-program-container .join-our-franchise-program-img-container {
  flex-basis: 900px;
}

.join-our-franchise-program-container .join-our-franchise-program-des-container {
  flex-basis: 1000px;
  flex-grow: 1;
}

.join-our-franchise-program-img-container .join-our-franchise-program-img {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  object-position: inherit;
}

/* .join-our-franchise-program {
    position: relative;
}

.join-our-franchise-program-img-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../../../../public/images/join-our-franchise-sec-left-new.jpg');
    background-image: url('./images/join-our-franchise-sec-left-new.jpg');
    background-size: cover;
    background-position: center;
} */

@media only screen and (max-width: 1024px) {
  .join-our-franchise-program-container {
    flex-wrap: wrap;
    gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  /* .join-our-franchise-program-container{
        flex-wrap: wrap;
        gap: 2rem;
    } */
  .join-our-franchise-program-container {
    margin: 4rem 0;
  }
}

@media only screen and (max-width: 600px) {
  .join-our-franchise-head-container > p {
    font-size: 24px;
  }
}
