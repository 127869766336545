.banner_container_cstm {
  /* margin: 0 -4rem; */
  margin-inline: calc(0px - var(--padding-inline));
}

.banner_container_styles_cstm {
  width: 100%;
  /* height: 75vh; */
  margin: 0 auto;
}

.left_arrow_styles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.right_arrow_styles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.dots_container_styles {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 30px;
}

@media only screen and (max-width: 600px) {
  /* .banner_container_cstm {
    margin: 0 -1.5rem;
    margin-inline: calc(0px - var(--padding-inline));
  } */
}
