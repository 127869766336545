/* Banner START */
.warranty-purchase-banner-container {
  background-color: var(--clr-primary);
  position: relative;
  height: 400px;
  margin-inline: -4rem;
}

@media only screen and (max-width: 600px) {
  .warranty-purchase-banner-container {
    margin-inline: -1.5rem;
  }
}

/* Banner END */

/* FORM START */

.ew-purchase-section {
  padding-inline: 4rem;
  margin-inline: -4rem;
  padding-block: 2rem;
  background-color: #fafafa;
  display: flex;
  gap: max(5%, 2rem);
  /* justify-content: space-between; */
}

.ew-purchase-section > .form-warranty-purchase {
  flex-grow: 1;
  max-width: 900px;
}

.ew-purchase-section > .form-warranty-purchase .form-error-text {
  margin-bottom: 0.5rem;
  font-size: 12px;
}

.ew-purchase-section > .summary-card {
  position: sticky;
  top: 100px;
  height: fit-content;

  /* background-color: var(--bg-primary); */
  flex-basis: 500px;
  flex-shrink: 0;
  padding: 2rem 1.5rem;
}

.ew-purchase-section form .form-section > h2 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.ew-purchase-section form .divide-two {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.ew-purchase-section form .divide-two > * {
  flex-grow: 1;
  flex-basis: 200px;
}

.ew-purchase-section form .field-container.form-upload-container .dragDropArea {
  min-height: 300px;
}

a#warranty-bosch-tnc:link,
a#warranty-bosch-tnc:visited,
/* a#warranty-bosch-tnc:hover, */
a#warranty-bosch-tnc:active {
  color: var(--clr-primary);
}

@media only screen and (max-width: 600px) {
  .ew-purchase-section {
    padding-inline: 1.5rem;
    margin-inline: -1.5rem;
  }

  .ew-purchase-section > .summary-card {
    display: none;
  }
}

/* FORM END */
