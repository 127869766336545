.contact-us-container {
  display: flex;
  margin: 5rem 0;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
}

.contact-us-container .contact-us-des-container {
  flex-basis: 900px;
  padding-right: 3rem;
  flex-grow: 1;
}

.contact-us-des-container .contact-us-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-us-des-container .contact-us-title > p {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
  padding-bottom: 3rem;
}

.contact-us-title .contact-us-img {
  width: 100%;
  border-radius: 8px;
}

.contact-us-form-container {
  flex-basis: 900px;
  padding-left: 3rem;
  flex-shrink: 1;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .contact-us-form-container .form-container {
    width: 100%;
  }

  .contact-us-container {
    flex-wrap: wrap;
  }

  .contact-us-form-container {
    padding: 3rem 0;
  }

  .contact-us-container .contact-us-des-container {
    padding-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-container .contact-us-des-container {
    padding-right: 0;
  }

  .contact-us-des-container .contact-us-title > p {
    font-size: 24px;
  }

  .contact-us-container {
    margin: 3rem 0;
    padding: 1.5rem;
  }
}
