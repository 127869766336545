.our-benefits-title-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.our-benefits-title-container > p {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 400;
}

span {
  font-weight: 800;
}

.our-benefits-container {
  display: flex;
  margin-top: 3rem;
  gap: 3rem;
}

.our-benefits-container .our-benefits-title-container {
  width: 60%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.our-benefits-container .our-benefits-card-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}

.our-benefits-container .our-benefits-tractor {
  padding-right: 0;
  margin-left: -4rem;
}

img.tractor-gradiant {
  width: 80%;
  height: 100%;
}

.our-benefits-card-container .our-benefits-card {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.our-benefits-card .our-benefits-title {
  color: var(--clr-primary);
  font-size: 20px;
  font-weight: 600;
}

.our-benefits-card .our-benefits-subtitle {
  color: var(--clr-gray);
  font-size: 14px;
  line-height: 1.4rem;
}

.our-benefits-btn {
  align-self: 'flex-start';
}

.banner-franchise-program-img .bnnr-map-img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .our-benefits-container {
    flex-direction: column;
  }

  .our-benefits-container .our-benefits-title-container {
    width: 100%;
    padding-bottom: 2rem;
  }

  .our-benefits-container .our-benefits-card-container {
    width: 100%;
    padding-left: 0;
  }

  .our-benefits-container .our-benefits-tractor {
    padding-right: 0;
  }

  img.tractor-gradiant {
    width: 100%;
  }

  .our-benefits-title-container > p {
    font-size: 24px;
  }

  .our-benefits-container {
    margin-top: 4rem;
  }
}
