/* Apply to All MUI Buttons */
.MuiButton-root.MuiButtonBase-root {
  border-radius: 3px;
  padding: 4px 16px;
  height: 32px;

  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  min-width: 119px;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined {
  /* border: 1px solid var(--clr-gray); */
}

.MuiButton-startIcon.MuiButton-iconSizeMedium > *:nth-of-type(1) {
  font-size: inherit;
}
