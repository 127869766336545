.how-it-works-container {
  display: flex;
  gap: 5rem;
  width: 100%;
}

.how-it-works-container .gif-container {
  flex-basis: 750px;
  background: var(--clr-primary);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  align-items: flex-end;
}

.gif-container .hiw-gif {
  /* width: 100%; */
  max-height: 85%;
  max-width: 95%;
  border-radius: 20px 20px 0px 0px;
}

.process-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.process-container .process-card-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex-basis: 700px;
  flex-grow: 1;
}

.process-card-container .process-card {
  border-radius: 7px;
  display: flex;
  gap: 1.5rem;
}

.process-card .process-card-title {
  color: var(--clr-primary);
  font-size: 20px;
  font-weight: 600;
}

.process-card .process-card-subtitle {
  color: var(--clr-primary);
  font-size: 14px;
  line-height: 1.4rem;
}

.process-card .process-card-icn {
  width: 50px;
}

.process-card .process-number-container {
  display: flex;
  align-items: center;
}

.process-card .process-card-des-container {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.process-card .process-number {
  font-weight: 700;
  color: #fff;
  width: 45px;
  height: 45px;
  background-color: #002852;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 9px rgb(166 180 194);
  font-size: 20px;
}

.process-card-head-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.process-card-head-container > h3 {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
  margin: 2% 0 0;
}

@media only screen and (max-width: 600px) {
  .process-container {
    margin: 4rem 0rem;
  }

  .how-it-works-container {
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 2rem;
  }

  .process-container .process-card-container {
    gap: 2rem;
    flex-direction: column;
  }

  .process-card-container .process-card {
    height: auto;
    padding: 0.5rem;
    flex-direction: column;
  }

  .process-card .process-number-container {
    bottom: -1.5rem;
  }

  .process-container {
    align-items: flex-start;
  }

  .process-card .process-card-subtitle {
    font-size: 18px;
    padding-right: 0;
  }

  .process-card-head-container > p {
    font-size: 24px;
  }

  .process-card .process-number {
    width: 35px;
    height: 35px;
  }
}

/* @media only screen and (max-width: 1024px){

    .process-container .process-card-container{
        gap: 2.5rem;
    }
}

@media only screen and (max-width: 768px){

    .process-container .process-card-container{
        gap: 2.5rem;
    }
} */
