.tractor-details .tractor-details-grid {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  gap: 2rem;
}

.tractor-details .tractor-details-grid .tractor-card {
  background-color: var(--bg-blue);
  padding: 1.5rem 1rem;
  border-radius: 8px;
}
.tractor-details .tractor-details-grid .tractor-card.deactiveCard {
  filter: grayscale();
}

.tractor-details .tractor-details-grid .tractor-card .tractor-header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0rem 0rem 1rem;
}

.tractor-details .icon-edit {
  display: flex;
  background-color: #d4dbe287;
  padding: 8px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  /* margin-top: -30px; */
  /* margin-right: -5px; */
}

.MuiAvatar-circular.tractor-details-user {
  background-color: #000;
  width: 55px;
  height: 55px;
}

.tractor-card .detail-title {
  font-size: 14px;
  color: var(--clr-gray);
}

.tractor-card > h3 {
  padding-bottom: 1rem;
}

.tractor-des-details {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.detail-des {
  font-size: 16px;
  color: var(--clr-black);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .tractor-details .tractor-details-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
