.carousel-container {
  margin: 0rem -4rem;
}

.carousel-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  /* Temporary For Bottom */
}

.service-selection-container .service-container {
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.service-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.service-container > h2 {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.service-container > p {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.service-container .service-card-container {
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-between; */
  /* width: inherit; */
  /* flex-direction: row-reverse; */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(3, 1fr);

  gap: 3rem;
}

/* .service-container .service-card-container > * { */
/* flex-grow: 1;
  flex-basis: 340px; */
/* } */
.service-container .service-container-heading > h1 {
  font-size: 36px;
  font-weight: 600;
}

.service-card-container .service-card {
  border-radius: 7px;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.06);
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.service-card-container .service__unavailable {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffaaaa;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  width: -webkit-fill-available;
  text-align: center;
  padding: 0.5rem;
  color: var(--clr-secondary);
  font-weight: 600;
  font-size: 12px;
}

.service-card .tag {
  display: flex;
  background-color: var(--bg-blue);
  gap: 4px;
  padding: 8px 16px;
  width: fit-content;
  font-weight: 600;
}

.service-card .task-list {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;
}

.service-card .task-list .action-text {
  font-size: 14px;
  color: var(--clr-secondary);
  text-decoration: underline;
  grid-column: 1/-1;
  cursor: pointer;
}

.service-card .card-title {
  color: var(--clr-primary);
  font-size: 24px;
  font-weight: 600;
}

.service-card .card-subtitle {
  color: var(--clr-gray);
  font-size: 14px;
}

.service-selection-container .tractor-model-list-container {
  position: relative;
  top: -4rem;
  width: min(100%, 700px);
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
  box-shadow: 1px 23px 52px -11px rgba(0, 0, 0, 0.12);
  /* padding: 2rem 2rem 1rem; */
  padding-block: 1rem;
}

.service-selection-container .tractor-model-list-container .field-container {
  flex-basis: 200px;
}

.service-selection-container .tractor-model-list-container button {
  flex-basis: 120px;
}

.service-card .card-btn {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.service-card .card-btn .submit-btn-parent {
  margin-top: auto;
}

@media only screen and (max-width: 1500px) {
  .service-card .card-btn {
    display: flex;
    flex-direction: column-reverse;
  }
  .service-card .card-btn .submit-btn-parent {
    width: fit-content;
    margin-top: 1rem;
  }
}

.service-card .card-btn span {
  font-size: 22px;
  color: var(--clr-black);
  font-weight: 600;
  padding: 4px;
  @media (min-width: 1500px) {
    margin-left: auto;
  }
}

.service-card .card-btn span.crossed {
  font-size: 18px;
  /* color: var(--clr-gray); */
  color: #c4c4c4;
  font-weight: 600;
  text-decoration: line-through;
}

.cart {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 1rem 4rem;
  /* height: 100px; */
  background-color: var(--clr-primary);
  color: white;
  box-shadow: 10px 10px 80px 10px #ffffff22;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.cart .cart-details {
  display: flex;
  gap: 1rem;
}

.cart .btn-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
  flex-basis: 100px;
  flex-grow: 1;
}

.cart .btn-container button {
  min-width: 100px;
}

.cart .cart-details .title {
  color: var(--clr-gray);
  font-size: 14px;
}

.cart .cart-details .details {
  color: white;
}

.popup.add-tractor .MuiDialog-paper {
  /* width: ; */
  width: min(100vw, 600px);
  min-width: min(100vw, 600px);
}

.popup.add-tractor .content-container > * {
  width: 100%;
}

.popup.add-tractor .info {
  background-color: rgba(236, 39, 38, 0.07);
  color: var(--clr-primary);
  font-weight: 600;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
}

.popup.add-tractor .tractor-details {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--bg-blue);
  padding: 0.5rem;
}

.popup.add-tractor .tractor-details p:nth-child(1) {
  color: var(--clr-gray);
  font-size: 14px;
  font-weight: 500;
}

.popup.add-tractor .tractor-details p:nth-child(2) {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 500;
}

/* .popup.add-tractor .form-container {} */

.popup.add-tractor .form-container .field-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.popup.add-tractor .form-container .btn-container {
  margin-top: 0.5rem;
}

.popup.add-tractor .form-container .field-row > * {
  flex-grow: 1;
  flex-basis: 200px;
}

.select-card-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  max-height: 60vh;
  overflow: auto;
}

.select-service-tractor-card {
  background-color: var(--bg-blue);
  padding: 1.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  transition: all 350ms;
}

.select-service-tractor-card:hover {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}
.tractor-model-list-container.tractor-selection {
  padding: 1.2rem 1.2rem 0px;
  width: min(100%, 950px);
  top: -2.5rem;
}

@media only screen and (max-width: 600px) {
  .carousel-container {
    margin: 0rem -1rem;
  }

  .service-container .service-container-heading > h1 {
    font-size: 25px;
  }

  .service-selection-container .tractor-model-list-container {
    position: relative;
    top: 3rem;
    width: min(100%, 700px);
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 7px;
    box-shadow: 1px 23px 52px -11px rgba(0, 0, 0, 0.12);
    /* padding: 2rem 2rem 1rem; */
  }

  .service-container .service-card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem;
    width: inherit;
    /* flex-direction: row-reverse; */
    gap: 3rem;
  }

  .service-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    /* Temporary For Bottom */
  }

  .service-card-container .service-card {
    border-radius: 7px;
    box-shadow: 1px 23px 52px -11px rgba(0, 0, 0, 0.12);
    padding: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .service-selection-container .service-container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
  }

  .service-selection-container .tractor-model-list-container .field-container {
    flex-basis: 400px;
  }

  .cart {
    padding: 1rem 1rem;
  }

  .choose-tractor > h1 {
    font-size: 20px;
    font-weight: 700;
  }
  .tractor-model-list-container.tractor-selection {
    padding: 1.2rem 1.2rem 0px;
    width: min(100%, 950px);
    top: -1.5rem;
  }
}
