.bosch_second_heading {
  font-size: 22px;
  font-weight: 600;
}

.p_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4rem;
  color: #000000;
  /* margin: bottom 10px; */
}

.p_text > ul {
  margin-bottom: 20px;
}
