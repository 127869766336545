.warranty-form-container {
  background-color: #fafafa;
  margin-inline: -4rem;
  padding: 2rem 4rem;
  scroll-margin-top: 80px;
}

.warranty-form-container > .title-container {
  margin: 1.5rem 0rem;
}

.warranty-form-container > .title-container > h2 {
  color: var(--clr-secondary);
  font-size: 1rem;
  font-weight: 600;
}

.warranty-form-container > .content-section {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}

.warranty-form-container > .content-section > .image-text-container {
  flex-basis: 300px;
  flex-grow: 1;
}

.warranty-form-container > .content-section > form {
  flex-basis: 300px;
  flex-grow: 1;
}

.warranty-form-container .image-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.warranty-form-container .image-text-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 1rem;
}

.warranty-form-container form > .divide-two {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.warranty-form-container form > .divide-two > * {
  flex-grow: 1;
  flex-basis: 200px;
}

.warranty-mini-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.warranty-mini-summary > * {
  max-width: 500px;
}

.warranty-mini-summary > .name {
  color: var(--clr-black);
  font-size: 2rem;
  font-weight: 600;
}

.warranty-mini-summary > .tractor-details {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
}

.warranty-mini-summary .details-section > p {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.warranty-mini-summary .details-section > p > span {
  padding-left: 8px;
  font-size: 1rem;
  color: var(--clr-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.warranty-mini-summary .details-section > div {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.warranty-mini-summary .details-block > div {
  color: var(--clr-gray);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.warranty-mini-summary .details-block p {
  color: var(--clr-primary);
  font-size: 1rem;
  font-weight: 600;
}

.warranty-mini-summary .purchase-card {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--bg-blue);
}

.warranty-mini-summary .purchase-card > * {
  padding: 1rem;
}

.warranty-mini-summary .purchase-card > .name-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 20px;
  background-color: var(--clr-primary);
  font-weight: 600;
}

.warranty-mini-summary .purchase-card > .description-list {
  padding-left: 2.5rem;
  margin-bottom: 0px;
  color: var(--clr-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

.warranty-mini-summary .purchase-card > .purchase-action {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.warranty-mini-summary .purchase-card .purchase-action > .price {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-basis: 220px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
  color: var(--clr-primary);
  font-weight: 600;
}

.warranty-mini-summary .purchase-card .purchase-action > .price > p {
  font-size: 1.25rem;
}

.warranty-mini-summary .purchase-card .purchase-action > .price > span {
  color: var(--clr-gray);
  font-size: 10px;
  font-weight: 400;
  margin-left: 4px;
}

@media only screen and (max-width: 600px) {
  .warranty-form-container {
    margin-inline: -1.5rem;
  }

  .warranty-form-container {
    padding: 2rem 1.5rem;
  }
}
