.workshop-view-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.workshop-view-container .title-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.workshop-view-container .workshop-statistics-container {
  display: grid;
  gap: 32px;
  grid-template-areas:
    'ws-profile ws-balance ws-earning ws-team'
    'ws-profile ws-sko     ws-booking ws-booking';
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.workshop-statistics-container > * {
  min-height: 200px;
  background-color: white;
  border-radius: 8px;
}

.workshop-statistics-container .ws-profile {
  grid-area: ws-profile;
}

.workshop-statistics-container .ws-balance {
  grid-area: ws-balance;
}

.workshop-statistics-container .ws-earning {
  grid-area: ws-earning;
}

.workshop-statistics-container .ws-team {
  grid-area: ws-team;
}

.workshop-statistics-container .ws-sko {
  grid-area: ws-sko;
}

.workshop-statistics-container .ws-booking {
  grid-area: ws-booking;
}

.ws-profile .card-info {
  padding: 32px 40px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-info .btn-edit {
  position: absolute;
  top: 32px;
  right: 40px;
}

.card-info .icon-ws .info-image {
  width: 64px;
  height: 64px;
}

.card-info .title-ws {
  font-size: 22px;
  font-weight: 600;
  color: var(--clr-primary);
}

.card-info .profile-ws {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card-info .profile-block {
  display: flex;
  gap: 8px;
}

.profile-block .icon-profile-ws {
  font-size: 24px;
}

.profile-block > div > p {
  font-size: 16px;
  line-height: 20px;
  color: var(--clr-gray);
  margin-bottom: 4px;
}

.profile-block > div > div {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: var(--clr-black);
}

.workshop-view-container .workshop-otherinfo-container {
  display: grid;
  gap: 32px;
  grid-template-areas: 'ws-otherinfo ws-holiday';
  grid-template-columns: 1.5fr 1fr;
}

.workshop-otherinfo-container > * {
  min-height: 250px;
  background-color: white;
  border-radius: 8px;
}

.workshop-otherinfo-container .ws-otherinfo {
  grid-area: ws-otherinfo;
}

.workshop-otherinfo-container .ws-holiday {
  grid-area: ws-holiday;
}

.workshop-view-container .workshop-viewtab-container {
  background-color: white;
  padding: 32px;
}

.workshop-viewtab-container .MuiTabs-flexContainer > * {
  flex-grow: 1;
  align-items: flex-start;
  padding-left: 2px;
}

.card-otherinfo-container {
  padding: 32px 40px;
}

.card-otherinfo-container .otherinfo-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.card-otherinfo-container .otherinfo-title p {
  font-size: 18px;
  font-weight: 600;
}

.card-otherinfo-container .otherinfo-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.card-otherinfo-container .otherinfo-grid p {
  font-size: 16px;
  line-height: 20px;
  color: var(--clr-gray);
}

.card-otherinfo-container .otherinfo-grid div {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
}

.card-holiday-container {
  padding: 32px 40px;
}

.card-holiday-container .holiday-title p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  min-height: 32px;
}

.card-holiday-container .card-holiday-grid > p {
  font-size: 16px;
  line-height: 20px;
  color: var(--clr-gray);
}

.card-holiday-container .card-holiday-grid > div {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
}

.card-holiday-container .card-holiday-grid {
  display: grid;
  gap: 8px;
  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  grid-template-columns: 190px 190px;
}

.card-otherinfo-container {
  padding: 32px 40px;
}

.card-otherinfo-container .otherinfo-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.card-otherinfo-container .otherinfo-title p {
  font-size: 18px;
  font-weight: 600;
}

.card-otherinfo-container .otherinfo-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.card-otherinfo-container .otherinfo-grid p {
  font-size: 16px;
  line-height: 20px;
  color: var(--clr-gray);
}

.card-otherinfo-container .otherinfo-grid div {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
}

.card-holiday-container {
  padding: 32px 40px;
}

.card-holiday-container .holiday-title p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  min-height: 32px;
}

.card-holiday-container .card-holiday-grid > p {
  font-size: 16px;
  line-height: 20px;
  color: var(--clr-gray);
}

.card-holiday-container .card-holiday-grid > div {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
}

.card-holiday-container .card-holiday-grid {
  display: grid;
  gap: 8px;
  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  grid-template-columns: 190px 190px;
}

.workshop-pricing-wallet {
  background: #f2f7ff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
}

.workshop-pricing-wallet .wallet-amount {
  font-size: 22px;
  font-weight: 600;
}

.workshop-pricing-wallet .wallet-text {
  color: #6d7d93;
  font-weight: 500;
}
