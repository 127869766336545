/* Apply to All MUI TextFields */
.MuiTextField-root .MuiInputBase-input {
  padding: 10px;
  font-size: 14px;
  color: var(--clr-primary);
  height: 12px;
}

.MuiInputBase-root.MuiInputBase-multiline {
  padding: 0;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}
