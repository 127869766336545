/* .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root{
    flex-direction: row !important;
} */
.faqs-container {
  margin: 7rem 0;
}

.faqs-container .faqs-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.faqs-heading-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.faqs-heading-container > h3 {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.faqs-container .accordions-container {
  max-width: 800px;
  margin: 0 auto;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  flex-direction: row;
  background-color: transparent;
  padding: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root {
  border: 0px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* .MuiPaper-root.MuiPaper-elevation.MuiAccordion-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
} */
.MuiPaper-root.MuiPaper-elevation.MuiAccordion-root:last-child {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12);
}

.MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content {
  /* margin-left: 0; */
  margin: 15px 0;
}

.MuiCollapse-wrapperInner .MuiAccordionDetails-root {
  padding: 25px 0px;
}

.MuiAccordionSummary-content > p {
  font-size: 20px;
  color: #000;
}

.MuiAccordionDetails-root > p {
  font-size: 14px;
  color: #7f7f7f;
}

@media only screen and (max-width: 600px) {
  .faqs-container .faqs-heading-container {
    align-items: flex-start;
  }

  .faqs-container .accordions-container {
    margin: 0;
  }

  .MuiPaper-root .MuiButtonBase-root .MuiAccordionSummary-content {
    margin: 20px 0;
  }

  .faqs-container .faqs-heading-container {
    margin-bottom: 1rem;
  }

  .faqs-container {
    margin: 2rem 0;
  }

  /* .MuiAccordionSummary-content>p {
        line-height: 1.6rem;
    } */
}
