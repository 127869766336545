.tab-franchise-program-container {
  margin: 0 -4rem;
  padding: 4rem 0;
  background-color: #002852;
}

.tab-franchise-program-container .MuiTabs-root .MuiTab-root {
  color: var(--bg-secondary);
  padding-left: 20px;
  padding-right: 20px;
}

.tab-franchise-program-container .MuiTabs-scroller .MuiTabs-flexContainer {
  justify-content: space-between;
  gap: 2rem;
  margin: 0 3rem;
}

.tab-inner-container {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  background-color: #fff;
  margin: 4rem;
  padding: 4rem;
  border-radius: 8px;
}

.tab-inner-heading > h5 {
  font-size: 32px;
  font-weight: 600;
  line-height: 2rem;
}

.tab-inner-des > p {
  color: var(--clr-gray);
  font-size: 14px;
  line-height: 1.4rem;
}

.tab-inner-container .tab-inner-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  flex-basis: 900px;
  flex-grow: 1;
}

.tab-inner-img-container {
  flex-basis: 700px;
}

.tab-inner-img-container .tab-inner-img {
  width: 100%;
  border-radius: 8px;
}

.tab-franchise-program-container .franchise-program-head-container {
  text-align: center;
  padding: 0rem 1rem 2rem;
}

.franchise-program-head-container > h5 {
  font-size: 16px;
  color: var(--bg-secondary);
  /* color: var(--clr-secondary); */
  font-weight: 500;
  padding-bottom: 1.5rem;
}

.franchise-program-head-container .franchise-program-subtitle {
  font-size: 32px;
  color: var(--bg-secondary);
  font-weight: 600;
  padding-bottom: 1.5rem;
  /* padding: 0rem 20rem 1rem; */
}

.franchise-program-head-container .franchise-program-des {
  font-size: 14px;
  line-height: 1.4rem;
  color: var(--bg-secondary);
  /* padding: 0 15rem; */
}

.MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons {
  color: #fff;
}

.tab-franchise-program-container .MuiTabs-root .MuiTab-root.Mui-selected {
  color: var(--clr-secondary);
}

.tab-franchise-program-container .MuiBox-root {
  border-bottom: none;
}

@media only screen and (max-width: 768px) {
  .tab-franchise-program-container {
    padding: 4rem 0 2.5rem;
  }

  .tab-franchise-program-container .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: unset;
  }

  .tab-inner-container {
    flex-direction: column-reverse;
    padding: 2rem;
    gap: 1rem;
  }

  .tab-inner-container .tab-inner-content-container {
    flex-basis: fit-content;
  }

  .tab-inner-img-container {
    flex-basis: fit-content;
  }

  .tab-franchise-program-container .MuiTabs-scroller .MuiTabs-flexContainer {
    margin: 0 2rem;
  }

  .tab-container .franchise-program-head-container {
    text-align: left;
  }
}

@media only screen and (max-width: 600px) {
  .tab-franchise-program-container {
    margin: 0 -1.5rem;
  }

  .tab-inner-container {
    flex-direction: column-reverse;
    padding: 1.5rem;
    gap: 1rem;
    margin: 0;
  }

  .tab-inner-container .tab-inner-content-container {
    flex-basis: fit-content;
  }

  .tab-inner-img-container {
    flex-basis: fit-content;
  }

  .tab-inner-heading > h5 {
    font-size: 24px;
  }

  .tab-franchise-program-container .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: unset;
  }

  .franchise-program-head-container .franchise-program-subtitle {
    font-size: 24px;
  }
}
