.get-started-process-container {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  margin-bottom: 7%;
}

.get-started-process-container .join-our-franchise-head-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.get-started-process-container .get-started-process-card-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.get-started-process-container .get-started-process-card {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.get-started-process-card .get-started-title {
  color: var(--clr-primary);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.get-started-process-card .get-started-icn {
  align-self: center;
}

.get-started-icn .icn {
  width: 55px;
}

.get-started-process-card .get-started-subtitle {
  text-align: center;
  color: var(--clr-gray);
  font-size: 14px;
  line-height: 1.4rem;
}

.get-started-process-container .process-arrow-container {
  margin: 1% 0 0 0;
}

@media only screen and (max-width: 768px) {
  .get-started-process-container .get-started-process-card-container {
    flex-wrap: wrap;
  }
  .process-arrow-container .process-arrow {
    transform: rotate(90deg);
  }
  .get-started-process-container .process-arrow-container {
    margin: 4% 0 4% 0;
  }
  .get-started-process-container .join-our-franchise-head-container {
    align-items: flex-start;
  }
  .get-started-process-container {
    margin-bottom: 4rem;
  }
}
