.login-page {
  position: relative;
  background-image: url('/public/images/LoginBackground.jpg');
  height: calc(100vh - (83px));
  background-size: cover;
  background-position: start;
  font-size: 16px;
  margin: 0 calc(var(--padding-inline) * -1) -2rem;
}

.login-page .brand-title-container {
  width: 45vw;
  margin-left: 5vw;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
}

.login-page .brand-title-container h3 {
  font-size: 3em;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
}

.login-page .brand-title-container h3.with-us {
  font-weight: 700;
}

.login-page .blue-line {
  margin: 2vh 0;
  width: 8em;
  height: 8px;
  background-color: var(--clr-primary, #002852);
}

.login-page .brand-title-container > img {
  position: absolute;
  bottom: 0;
}

.login-page .login-form-container {
  position: absolute;
  right: 10vw;
  bottom: 0;

  background-color: #fafafa;
  border-radius: 24px 24px 0px 0px;
  min-width: 350px;
  min-height: 500px;
  padding: 2rem;
}

.login-form-container img {
  width: 160px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.login-page .login-form-container .login-form {
  display: flex;
  flex-direction: column;
}

.login-form .title {
  font-size: 22px;
  color: var(--clr-primary);
  font-weight: 700;
}

.login-form .error-text {
  font-size: 13px;
  color: var(--clr-secondary);
}

.login-form .field-input {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.login-form .resend-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.login-form .resend-info p {
  font-size: 14px;
}

.login-form .resend-info button {
  font-size: 14px;
  padding: 0px;
  text-decoration: underline;
}

.login-form.profile-form .field-input {
  display: flex;
  flex-direction: column;
}

.login-form.profile-form .field-input .error-text {
  height: 16px;
}

.login-form.profile-form .field-input label {
  margin-bottom: 4px;
  font-size: 14px;
}

.otp-field {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.login-field-otp {
  display: inline !important;
  width: 65px;
  font-size: 14px;
  text-align: center;
}

.otp-field .MuiTextField-root .MuiInputBase-input {
  padding: 15px 15px 15px 15px;
  border: 1px;
  font-size: 14px;
  height: 15px;
}

.login-form .MuiInputBase-input {
  padding: 10px;
  font-size: 14px;
  color: var(--clr-primary);
  height: 12px;
}

@media only screen and (max-width: 600px) {
  .login-page {
    margin: 0 -1.5rem -2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .login-page .brand-title-container {
    width: 40vw;
  }

  .login-page .brand-title-container > img {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .login-page .brand-title-container {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .login-page .login-form-container {
    min-height: calc(100vh - (83px));
    min-width: 100vw;
    right: 0;
    border-radius: 0;
  }
}
/* address scrollbar  */
/* Target the specific class for custom scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: red; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners of the track */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin; /* Make scrollbar thinner */
  scrollbar-color: red #f1f1f1; /* thumb color track color */
}
