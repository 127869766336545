.warranty {
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  gap: 4rem;
}

.warranty .wcantainer {
  display: flex;
  gap: 4rem;
}

.warranty .wcantainer.container2 {
  flex-direction: row-reverse;
}

.warranty .wcantainer > * {
  width: calc(50% - 2rem);
}

.warranty .images {
  object-fit: cover;
  border-radius: 8px;
}

@media only screen and (min-width: 1400px) {
  .warranty .images {
    max-height: 350px;
  }
}

@media only screen and (max-width: 900px) {
  .warranty .wcantainer.container2,
  .warranty .wcantainer {
    flex-direction: column;
  }

  .warranty .wcantainer > * {
    width: 100%;
  }
}
