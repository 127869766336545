.items,
.kits {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 2.5rem 0 4rem;
}

.items {
  margin-bottom: 4rem;
}

.items .items-list,
.kits .kits-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 32px;
  margin-top: 1rem;
}

.items .items-list .item-card,
.kits .kits-list .kit-card {
  max-width: 250px;
}

.items .items-list .item-card.not_avilable {
  opacity: 0.95;
  filter: grayscale(1);
}

.items .items-list .item-card .item-image {
  position: relative;
  margin-bottom: 3%;
}

.items .items-list .item-card h3 {
  margin-bottom: 1%;
  min-height: 3rem;
}

.items .items-list .item-card .prices {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item-card .prices > .offer-price {
  font-size: 16px;
  font-weight: 600;
  text-wrap: nowrap;
  margin-right: 1rem;
}

.item-card .prices > .mrp-price {
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  text-wrap: nowrap;
  margin-right: 1rem;
}

.items .items-list .card,
.kits .kits-list .card {
  position: relative;
}

.items .items-list .card > div.item__unavailable_parent > p.item__unavailable,
.kits .kits-list .card > div.item__unavailable_parent > p.item__unavailable,
.kits .kits-list .card > div.box > div.item__unavailable_parent > p.item__unavailable {
  position: absolute;
  top: 0;
  background-color: #ffaaaa;
  padding: 0.5rem;
  color: var(--clr-secondary);
  font-weight: 600;
  font-size: 12px;
  width: -webkit-fill-available;
  text-align: center;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  margin: 1% 1.5% 0% 1.5%;
}
@media only screen and (max-width: 600px) {
  .items .items-list .card > div.item__unavailable_parent > p.item__unavailable,
  .kits .kits-list .card .box > div.item__unavailable_parent > p.item__unavailable,
  .kits .kits-list .card > div.item__unavailable_parent > p.item_and_img_unavailble,
  .kits .kits-list .card > div.box > div.item__unavailable_parent > p.item__unavailable {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffaaaa;
    padding: 0.5rem;
    color: var(--clr-secondary);
    font-weight: 600;
    font-size: 12px;
    width: -webkit-fill-available;
    text-align: center;
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 0;
  }
  .items .items-list .card .box,
  .kits .kits-list .card .box {
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.2),
      0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 9px;
    padding: 0.8rem 0.5rem 0.5rem 0.5rem;
  }
  .kits .kits-list .card .box {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  .kits .kits-list .card .box .kit-card.not_avilable {
    padding-top: 2rem;
  }
  .items .items-list .card .box .item-card.not_avilable {
    margin-top: 2rem;
  }
}

.items .items-list .card > div.item__unavailable_parent > p.item_and_img_unavailble,
.kits .kits-list .card > div.item__unavailable_parent > p.item_and_img_unavailble {
  border-radius: 0rem;
  margin: 0%;
}

.kits .kits-list .kit-card.not_avilable {
  opacity: 0.95;
  filter: grayscale(1);
}

.kits .kits-list .kit-card .item-image {
  position: relative;
  margin-bottom: 3%;
}

.kits .kits-list .kit-card h3 {
  margin-bottom: 1%;
  min-height: 2rem;
}

.kits .kits-list .kit-card .prices {
  margin-bottom: 2%;
  display: flex;
  gap: 0.45rem;
  align-items: center;
  flex-wrap: wrap;
}

.kits .kits-list .kit-card .sub_items {
  min-height: 2.5rem;
}

.kit-card .prices > .offer-price {
  font-size: 16px;
  font-weight: 600;
  flex: 0 1 auto;
  white-space: nowrap;
}

.kit-card .prices > .mrp-price {
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  flex: 0 1 auto;
  white-space: nowrap;
}
.kit-card .prices > .discount-price {
  flex: 0 1 auto;
  white-space: nowrap;
}

.all-items-list .items h4,
.all-items-list .kits h4 {
  font-size: 36px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .items,
  .kits {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0rem;
  }

  .items .items-list,
  .kits .kits-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .items .items-list .item-card,
  .kits .kits-list .kit-card {
    max-width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .kits .kits-list .card .box .kit-card .content {
    display: flex;
    flex-direction: column;
    min-width: 60%;
  }

  .kits .kits-list .kit-card .content .heading .kit-items-section {
    /* need to verification for mobile */
    display: none;
  }

  .kits .kits-list .kit-card .item-image,
  .items .items-list .item-card .item-image {
    max-width: 40%;
    margin-bottom: 0;
  }
  .items .items-list .card > p.item__unavailable,
  .kits .kits-list .card > p.item__unavailable {
    font-size: 9px;
  }
  .all-items-list .items h4,
  .all-items-list .kits h4 {
    font-size: 25px;
  }
}
