* {
  box-sizing: border-box;
}

/* .backgroung {
    background-color: #f9f9f9;
    padding: 0px 50px;
    margin: 50px auto;
    padding-top: 20px;

} */

.major-benifit {
  display: flex;
  flex-wrap: wrap;
  margin: 50px auto;
  row-gap: 1rem;
  column-gap: 4rem;
}

.major-benifit > * {
  flex-grow: 1;
  flex-basis: calc(50% - 2rem);
}

.major-benifit-intro {
  display: flex;
}

.major-benifit-intro2 {
  align-content: flex-end;
  color: var(--clr-primary);
  line-height: 1.2;
}

.intro-heading {
  flex-basis: 100%;
}

.intro-heading h5 {
  font-size: 16px;
  font-weight: 400px;
  line-height: 30px;
  color: #ec2726;
}

.intro-policy {
  font-size: 22px;
  line-height: 30px;
  align-items: center;
}

.major-benefit-cantainer-card {
  display: grid;
  grid-template-columns: repeat(4, 0.5fr);
  gap: 1.2rem;
  margin-bottom: 5rem;
}

.major-benefit-cantainer-card > h3 {
  grid-column: 1/-1;
  font-weight: 700;
}

.major-benefits-card {
  background-color: var(--bg-secondary);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid #00000014; */
  /* box-shadow: 10px 10px 10px 10px #000000; */
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.06);
  /* opacity: 0.1; */
  border-radius: 8px;
  /* transition: all 1000ms 1000ms; */
}

.major-benefits-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--clr-primary);
  font-weight: 600;
}

.major-benefits-subtitle {
  font-size: 14px;
  line-height: 1.4rem;
  color: var(--clr-gray);
}

.major-benefit-cantainer-card .our-benefits-card,
.major-benefit-cantainer-card .our-benefits-card .our-benefits-title,
.major-benefit-cantainer-card .our-benefits-card .our-benefits-subtitle,
.major-benefit-cantainer-card .our-benefits-card .hoverable {
  transition: all 5000ms;
}

.major-benefit-cantainer-card .major-benefits-card:hover {
  background-color: var(--clr-secondary);
}

.major-benefit-cantainer-card .major-benefits-card:hover .major-benefits-title {
  color: var(--bg-secondary);
}

.major-benefit-cantainer-card .major-benefits-card:hover .major-benefits-subtitle {
  color: var(--bg-secondary);
}

.major-benefit-cantainer-card .major-benefits-card:hover .hoverable {
  --clr-primary: #002852;
  --clr-secondary: #fff;
}

@media only screen and (max-width: 1024px) {
  .major-benifit-intro2 {
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .major-benefit-cantainer-card {
    grid-template-columns: repeat(2, 0.7fr);
    gap: 30px;
  }

  .about-us-our-benefits-card-container {
    padding-left: unset;
  }
}

@media only screen and (max-width: 768px) {
  .major-benifit {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-our-benefits-card-container {
    gap: 3rem 2rem;
  }

  .major-benefit-cantainer-card .major-benefits-card {
    background-color: unset;
    padding: 2rem 1rem;
    border: unset;
    border-radius: unset;
  }

  .major-benefit-cantainer-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
