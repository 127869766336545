.customer-details-div {
  padding-block: 12px;
  border-bottom: 1px solid #0028521a;
}
.customer-details-div > p {
  color: var(--clr-primary);
  font-weight: 600;
  font-size: 18px;
}
.customer-details-child {
  display: flex;
  justify-content: space-between;
  padding-block: 12px;
}
.customer-details-sub-child {
  display: flex;
  gap: 48px;
}
.personal-info-div {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
.personal-info-div-p {
  color: #6d7d93;
  font-weight: 500;
  font-size: 1rem;
}
.workshop {
  display: flex;
  justify-content: space-between;
  flex: 1 1 500px;
  border: 1px solid #0028521a;
}
.workshop-p {
  color: var(--clr-primary);
  font-weight: 600;
  font-size: 18px;
}
.workshop-p-span {
  color: var(--clr-gray);
  font-weight: 500;
  font-size: 14px;
}
.workshop-content-div {
  display: flex;
  justify-content: space-between;
  padding-block: 12px;
}
.workshop-content-child {
  display: flex;
  gap: 48px;
}
.workshop-content-sub-child {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.workshop-content-p {
  color: #6d7d93;
  font-weight: 500;
  font-size: 1rem;
}
