main.layout {
  background-color: white;
  --padding-inline: 7rem;
}

/* main.layout>header {} */

/* main.layout>footer {} */

main.layout > section {
  padding-inline: var(--padding-inline);
}

@media only screen and (min-width: 750px) and (max-width: 1300px) {
  main.layout {
    --padding-inline: 4rem;
  }
}

@media only screen and (max-width: 600px) {
  main.layout {
    --padding-inline: 1rem;
  }
}

.copyright {
  text-align: center;
  font-size: 18px;
  color: var(--clr-primary);
  font-weight: 600;
  /* padding-bottom: 1rem; */
}
