.header-blue {
  --clr-primary: #ffffff;
  --clr-secondary: #ffffff;
  --clr-gray: #6d7d93;
  --clr-black: #181c32;

  --bg-primary: #f5f8fa;
  --bg-secondary: #002852;
  --bg-blue: #f2f7ff;
}

.header-blue .MuiAvatar-root {
  color: var(--bg-secondary);
}

header#header {
  position: sticky;
  top: 0;
  /* padding: 2rem 4rem; */
  /* background-color: var(--bg-secondary); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.header-home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-home > .logo {
  position: absolute;
  left: var(--padding-inline);
  margin: 0 auto;
}

.header-menu {
  display: flex;
  /* gap: 3.5rem; */
  gap: 1.5rem;
  justify-content: center;
}

.header-home > .header-cart {
  position: absolute;
  right: 0;
}

/* .header-home > .header-profile-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  right: 0;
  margin: 0 auto;
} */

.header-home > .header-profile-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  right: var(--padding-inline);
  margin: 0 auto;
}

.header-profile-container > .profile {
  display: flex;
  gap: 0.5rem;
}

.header-user-details .header-username {
  color: var(--clr-primary);
  font-weight: 600;
  font-size: 18px;
}

.header-user-details .header-userrole {
  color: var(--clr-gray);
  font-size: 14px;
}

.header-menu > a {
  text-decoration: none;
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 500;
}

.menu-icon {
  display: flex;
}

.menu-details .menu-text-header {
  font-size: 16px;
  color: var(--clr-primary);
  font-weight: 600;
}

.menu-details .menu-text-subheader {
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

div.logo {
  cursor: pointer;
}

div.header-user-details .header-username {
  cursor: pointer;
}

.header-blue .MuiButton-root.MuiButton-outlined {
  /* color: #002852; */
  background-color: #ffffff;
}

.header-blue .start-icon-language {
  --clr-primary: #002852;
  --clr-secondary: #ec2726;
}

.language-menu .language-option:hover {
  background-color: #ffdede;
}

.language-menu .language-option.active {
  background-color: #ec2726;
  color: #fff;
}

.language .language-menu-selector {
  cursor: pointer;
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
}

@media only screen and (max-width: 350px) {
  .header-home > .logo > svg {
    height: 24px;
  }

  .header-home > .logo > svg:nth-child(1) {
    width: 40px;
  }

  .header-home > .logo > svg:nth-child(2) {
    width: 80px;
  }
}

@media only screen and (max-width: 600px) {
  header#header {
    padding: 0rem 1rem;
    background-color: var(--bg-secondary);
  }

  .header-home > .logo {
    position: relative;
    left: 0;
    margin: 0 auto;
    order: 1;
  }

  .header-menu {
    display: flex;
    gap: 1rem;
    justify-content: center;
    /* order: 1; */
    position: absolute;
    left: 0;
  }

  .header-home > .header-profile-container {
    display: flex;
    gap: 1rem;
    position: absolute;
    right: 0;
    margin: 0 auto;
    /* order: 3; */
  }

  a.sidebar-main-link {
    text-decoration: none;
    color: var(--clr-primary);
    font-size: 16px;
    font-weight: 500;
  }
}
