.banner-about-container {
  display: flex;
  background: url(/public/images/about-us-banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  /* margin: 0 -4rem; */
  margin-inline: calc(0px - var(--padding-inline));
  padding: 0 4rem;
  height: 650px;
}

.banner-about-container .banner-about-des-container {
  flex-basis: 1100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.banner-about-container .banner-about-img-container {
  flex-basis: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.banner-about-des-container .banner-about-title {
  font-weight: 700;
  color: #fff;
  font-size: 45px;
}

.banner-about-des-container .banner-about-subtitle {
  color: #fff;
  font-size: 25px;
  line-height: 2.5rem;
  padding-right: 17%;
}

.trct-img {
  width: 100%;
  height: 100%;
  padding: 6%;
  margin-top: 4%;
  margin-bottom: -10%;
}

@media screen and (min-width: 750px) and (max-width: 1500px) {
  .banner-about-container .banner-about-des-container {
    gap: 12px;
  }

  .banner-about-des-container .banner-about-title {
    font-size: 32px;
  }

  .banner-about-des-container .banner-about-subtitle {
    font-size: 18px;
    line-height: 1.8rem;
  }

  .banner-cta-about-container .banner-about-img-container {
    background-size: cover;
  }
}

@media only screen and (max-width: 600px) {
  .banner-about-des-container .banner-about-title {
    padding: 10% 0% 5%;
  }

  .get-started-process-container .get-started-process-card-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner-about-container {
    flex-wrap: wrap;
    margin: 0 -1.5rem;
    padding: 0 1rem;
  }
  .banner-about-container {
    background: url(/public/images/franchise-store-banner-responsive.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
