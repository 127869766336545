.maindiv {
  display: flex;
  margin: 100px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.cantiner {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.icon {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.icon img {
  width: 70px;
  height: 70px;
}

.infocantain {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #000000;
  line-height: 1.5rem;
}

.infocantain p:first-child {
  color: var(--clr-secondary);
  font-size: 25px;
  align-items: center;
  padding: 10px 0;
  font-weight: 600;
}

.line {
  width: 0.5px;
  height: 50px;
  background-color: #002852;
  align-self: center;
}

@media only screen and (max-width: 1240px) {
  .maindiv {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 930px) {
  .cantiner {
    gap: 25px;
    /* display: table; */
  }

  .maindiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* flex-wrap: wrap; */
    /* justify-content: flex-start; */
  }

  .infocantain {
    overflow-wrap: anywhere;
  }

  .line {
    display: none;
  }
}

/* @media only screen and (max-width:790px) {
    .line {
        display: none;
        
    }

} */
@media only screen and (max-width: 768px) {
  .cantiner {
    flex-direction: column;
    gap: unset;
  }
}

/* @media only screen and (max-width:600px) {
    .cantiner {
        flex-direction: column;
        gap: unset;
    }
} */

@media only screen and (max-width: 500px) {
  /* .cantiner {
        flex-direction: column;
        gap: unset;
    } */
  .maindiv {
    /* display: grid; */
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
