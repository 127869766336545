/* .main_container > div > h2 {
   font-size: 50px;
} */
.para_text a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.terms_main_container {
  padding: 43px;
}
/* .Privacy_policy{
    padding: 10px 0px 10px 0px;
} */
.container_terms .para_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4rem;
  color: #000000;
}
.container_terms > .h1 {
  font-size: 34px;
  font-weight: 600;
}
a.mail_url {
  color: #000000;
}
.container_terms > h2 {
  font-size: 22px;
  font-weight: 600;
}
.container_terms > li {
  line-height: 20px;
}

@media only screen and (max-width: 600px) {
  .terms_main_container {
    padding: 20px;
  }
  .container_terms {
    padding: 20px 0px 0px 0px;
  }
  .container_terms .para_text {
    font-weight: 500;
    font-size: 14px;
    /* line-height: 25px; */
  }
  .container_terms > h1 {
    font-size: 35px;
    font-weight: 600;
  }
  .container_terms > h2 {
    font-size: 24px;
    font-weight: 600;
  }
  .container_terms > li {
    line-height: 40px;
  }
  .para_text ol {
    padding: 0 5px 0px 25px;
  }
}
