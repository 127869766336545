.booking-container {
  margin-top: 3rem;
}

.booking-container h1 {
  color: black;
  margin-bottom: 2rem;
}

.booking-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin: 2rem 0rem;
}

.booking-card-container .booking-card {
  background-color: var(--bg-blue);
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
}

.booking-card .heading-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1rem;
}

.booking-card .heading-container > h3 {
  font-size: 24px;
}

.booking-card .heading-container > p {
  font-size: 20px;
  font-weight: 600;
}

.booking-card .heading-container > div {
  display: flex;
  gap: 8px;
  align-items: center;

  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
}

.booking-card .details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.booking-card .details-container > div {
  display: flex;
  align-items: center;
  flex-basis: 360px;
  gap: 1rem;
}

.booking-card .details-container .icon-container {
  display: flex;
  font-size: 2.5rem;
}

.booking-card .details-container .text-details .title {
  font-size: 16px;
  color: var(--clr-gray);
  font-weight: 500;
  margin-bottom: 4px;
}

.booking-card .details-container .text-details .details {
  font-size: 18px;
  font-weight: 600;
}
