.banner-customer-order-container {
  display: flex;
  background: url(/public/images/customer-order-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin: 0 calc(var(--padding-inline) * -1);
  padding: 0 4rem;
  height: 30vh;
}

.banner-customer-order-container .banner-about-img-container {
  flex-basis: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.trct-img {
  width: 100%;
  height: 100%;
  padding: 6%;
  margin-top: 4%;
  margin-bottom: -10%;
}

@media screen and (min-width: 750px) and (max-width: 1500px) {
  .banner-cta-about-container .banner-about-img-container {
    background-size: cover;
  }
}

@media only screen and (max-width: 600px) {
  .get-started-process-container .get-started-process-card-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner-customer-order-container {
    flex-wrap: wrap;
    margin: 0 -1.5rem;
    padding: 0 1rem;
  }
  .banner-customer-order-container {
    background: url(/public/images/customer-order-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
