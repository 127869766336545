.profile-container {
  margin-top: 3rem;
}

.profile-container h1 {
  color: black;
  margin-bottom: 2rem;
}

.profile-container > div {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.profile-container .profile-menu {
  flex-basis: 250px;
  padding: 1rem;
  background-color: var(--bg-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-radius: 12px;
}

.profile-container .profile-menu .MuiAvatar-root {
  background-color: var(--clr-primary);
  height: 120px;
  width: 120px;
}

.profile-container .user-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-primary);
}

.profile-container .profile-menu button {
  color: black;
  width: 100%;
  justify-content: flex-start;
  padding-left: calc(50% - 60px);
  display: flex;
  gap: 8px;
}

.profile-container .profile-menu button.Active {
  color: var(--clr-black);
  background-color: white;
  font-weight: 700;
}

.profile-container .profile-details {
  flex-grow: 1;
  flex-basis: 400px;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0px 5px 47px 0px hsla(0, 0%, 0%, 0.07);
}

.profile-container .profile-details .heading-container {
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
}

.form-container {
  /* width: min(100vw, 600px); */
  width: min(50vw, 600px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-container .column-two {
  display: flex;
  gap: 1rem;
}

.form-container .column-two .field-container {
  flex-grow: 1;
  flex-basis: 500px;
}

.popup.edit-profile .MuiDialog-paper {
  /* max-width: unset; */
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
}

.error-text {
  font-size: 12px;
  color: var(--clr-secondary);
  height: 14px;
  margin-top: 4px;
}
