.scroller {
  max-width: 650px;
}

.scroller_inner {
  /* padding-block: 1rem; */
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
}

.scroller[data-animated='true'] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, grey 20%, grey 80%, transparent);
  mask: linear-gradient(90deg, transparent, grey 20%, grey 80%, transparent);
}

.scroller[data-animated='true'] .scroller_inner {
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 45s) var(--_animation-direaction, forwards) linear infinite;

  width: min-content;
}

.scroller[data-direction='right'] {
  --_animation-direaction: reverse;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

@media (max-width: 600px) {
  .scroller {
    max-width: 300px;
  }

  /* .scroller[data-animated="true"] .scroller_inner {
    width: 400px;
  } */

  .scroller[data-animated='true'] {
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, transparent, grey 80%, grey 80%);
    mask: linear-gradient(90deg, grey 80%, grey 80%);
  }
}
