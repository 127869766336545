.discount {
  margin-top: 4px;
  border-radius: 3px;
  font-size: 12px;
  padding: 4px 5px;
  display: inline-block;
  background-color: #20a867;
  color: white;
  min-width: 65px;
  text-align: center;
}
