h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: var(--clr-primary);
}

h1 {
  font-size: 30px;
  /* line-height: 32.27px; */
  font-weight: 600;
  margin-top: -5px;
}

/* h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

p {} */

.text-overflow-ellipsis {
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
