.payment-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.popup .MuiDialog-paper {
  padding: 2rem;
}

.popup .content-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.popup .payment-status {
  padding: 2rem 5rem;
}
