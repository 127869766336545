.cta-app-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7rem;
  border-radius: 8px;
  background-color: #ec2726;
  position: relative;
  min-height: 400px;
}

.cta-app-container .app-des-container {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 2% 6% 2%;
}

.app-des-container .app-program-title {
  font-weight: 700;
  color: #fff;
  font-size: 45px;
}

.app-des-container .app-program-subtitle {
  color: #fff;
  font-size: 25px;
  line-height: 1.5;
}

.app-des-container .btn-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.app-des-container .app-cta-btn {
  width: 200px;
  cursor: pointer;
}

.cta-app-container .app-img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 440px;
  max-height: 110%;
}

@media only screen and (max-width: 1024px) {
  .cta-app-container .app-img {
    max-height: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .cta-app-container {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .cta-app-container .app-des-container {
    justify-content: center;
    align-items: center;
  }

  .cta-app-container .btn-container {
    justify-content: center;
    align-items: center;
  }

  .cta-app-container .app-img {
    position: static;
    width: 20rem;
    margin-top: 1rem;
    margin-bottom: -2rem;
    mask-image: linear-gradient(black 90%, transparent);
  }

  .cta-app-container .app-des-container {
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .app-des-container .app-cta-btn {
    width: 150px;
  }

  .app-des-container .app-program-title {
    font-size: 2rem;
  }

  .app-des-container .app-program-subtitle {
    font-size: 0.9rem;
  }
}
