.our-statistics-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 6rem;
}

.our-statistics-container > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
}

.our-statistics-container > h3 {
  font-size: 32px;
  color: var(--clr-primary);
  font-weight: 600;
}

.our-statistics-container .our-statistics-card-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 1rem;
}

.our-statistics-card-container .our-statistics-card {
  display: flex;
  gap: 1rem;
  width: 900px;
  justify-content: flex-start;
  padding-right: 2%;
  padding-left: 2%;
  border-right: 1px solid #b9b9b982;
  align-items: center;
}

.our-statistics-card-container .our-statistics-card:last-child {
  border-right: none;
  padding-right: 0;
}

.our-statistics-card .our-statistics-title-number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.our-statistics-card-icn-container .our-statistics-card-icn {
  width: 70px;
}

.our-statistics-title-number-container .our-statistics-number {
  font-size: 25px;
  font-weight: 700;
}

.our-statistics-title-number-container .our-statistics-card-title {
  font-size: 18px;
  color: #6d7d93;
}

@media only screen and (max-width: 768px) {
  .our-statistics-card-icn-container {
    align-self: center;
  }
  .our-statistics-container {
    margin-top: -1rem;
    align-items: flex-start;
  }
  .our-statistics-container .our-statistics-card-container {
    flex-wrap: wrap;
  }
  .our-statistics-card-container .our-statistics-card {
    justify-content: flex-start;
    border-right: 0px solid #b9b9b982;
    width: 50%;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .our-statistics-card .our-statistics-title-number-container {
    align-items: center;
  }
  .our-statistics-card-container .our-statistics-card {
    flex-direction: column;
  }
  .our-statistics-title-number-container .our-statistics-card-title {
    text-align: center;
  }
  .our-statistics-container > p {
    font-size: 24px;
  }
}
