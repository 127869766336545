footer {
  padding: 0rem var(--padding-inline);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--clr-primary);
  padding: 0rem var(--padding-inline);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--clr-primary);
}

footer.home-footer {
  padding-top: 10rem;
}
@media (max-width: 600px) {
  .home-footer {
    margin-top: -150px !important;
  }
}

footer .copyright {
  color: var(--bg-secondary);
  padding: 1rem 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--bg-secondary);
  padding: 1rem 0;
  font-size: 14px;
  font-weight: 400;
}

footer .pc-container {
  display: flex;
  column-gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.pc-container .tc-links {
  border-right: 1px solid var(--bg-secondary);
  border-right: 1px solid var(--bg-secondary);
}

.pc-container .tc-links:nth-child(4) {
  border-right: none;
  border-right: none;
}

.pc-container .tc-links a {
  color: var(--bg-secondary);
  text-decoration: none;
  /* border-right: 1px solid; */
  padding-right: 10px;
  color: var(--bg-secondary);
  text-decoration: none;
  /* border-right: 1px solid; */
  padding-right: 10px;
}

.pc-container .tc-links a:nth-child(3) {
  border-right: 0px solid;
  border-right: 0px solid;
}

.sub-footer-container {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  /* border-top: 1px solid #ffffff61; */
  flex-wrap: wrap;
}

.ask-title > p {
  color: var(--bg-secondary);
}

.ask-subtitle > p {
  color: var(--bg-secondary);
  font-size: 14px;
  line-height: 1.4rem;
}

.location-details > p {
  color: var(--bg-secondary);
  font-size: 14px;
  line-height: 1.4rem;
}

.mail-details > a {
  color: var(--bg-secondary);
  font-size: 14px;
  text-decoration: none;
}

.ask-phone-no > a {
  color: var(--bg-secondary);
  font-size: 25px;
  font-weight: 500;
  text-decoration: none;
  /* padding-bottom: 1rem; */
}

.abt-footer-title > p {
  color: var(--bg-secondary);
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.other-link-footer-title > p {
  color: var(--bg-secondary);
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 1rem;
}

.help-info-link-footer-title > p {
  color: var(--bg-secondary);
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 1rem;
}

.ask-container .contact-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  min-width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  min-width: max-content;
}

.contact-container > a {
  color: var(--bg-secondary);
  font-size: 25px;
  font-weight: 500;
  text-decoration: none;
  /* min-width: fit-content; */
}

footer .main-footer-container {
  display: flex;
  gap: 2rem;
  padding: 3rem 0 2rem;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  display: flex;
  gap: 2rem;
  padding: 3rem 0 2rem;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.abt-footer-links > li {
  list-style-type: none;
  color: var(--bg-secondary);
  line-height: 2rem;
  font-weight: 400;
  font-size: 14px;
}

.other-footer-links > li {
  list-style-type: none;
  color: var(--bg-secondary);
  line-height: 2rem;
  font-weight: 400;
  font-size: 14px;
}

.help-info-footer-links > li {
  list-style-type: none;
  color: var(--bg-secondary);
  /* line-height: 2rem; */
  padding: 3px;
  line-height: 1.3rem;
  font-weight: 400;
  font-size: 14px;
}

.help-info-footer-links > li > a {
  color: var(--bg-secondary);
  text-decoration: none;
}

.main-footer-container .ask-container {
  /* flex-basis: 25%; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-basis: 471px;
  /* padding: 20px 0px; */
  /* flex-grow: 5; */
  /* flex-basis: 25%; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-basis: 471px;
  /* padding: 20px 0px; */
  /* flex-grow: 5; */
}

.links-Container {
  display: flex;
  gap: 3.1rem;
  /* flex-wrap: wrap; */
  flex-grow: 1;
  /* align-content: space-between; */
  /* justify-content: space-between; */
  display: flex;
  gap: 3.1rem;
  /* flex-wrap: wrap; */
  flex-grow: 1;
  /* align-content: space-between; */
  /* justify-content: space-between; */
}

.main-footer-container .logo-social-container {
  flex-basis: 450px;
  flex-basis: 450px;
}

.links-Container > div {
  flex-basis: 400px;
  /* flex-grow: 1; */
}

.links-Container li {
  line-height: 1rem;
  margin-bottom: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.help-info-footer-links .location-container,
.mail-container {
  display: flex;
  gap: 1rem;
  display: flex;
  gap: 1rem;
}

.help-info-link-container .help-info-footer-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mail-container .mail-details {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.ask-title > p {
  font-size: 2rem;
  color: var(--bg-secondary);
  font-weight: 600;
  /* padding-right: 40%; */
}

.social-media-container .social-media-icn-container {
  gap: 1rem;
  display: flex;
  gap: 1rem;
  display: flex;
}

.social-media-icn-container > img {
  width: 40px;
}

@media only screen and (max-width: 1200px) {
  footer .main-footer-container {
    gap: 2rem;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 750px) and (max-width: 1450px) {
  .ask-title > p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  footer {
    padding: 0rem 1rem;
  }

  .main-footer-container .ask-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-basis: 400px;
    /* padding: 20px 0px; */
    /* flex-grow: 5; */
  }

  footer .main-footer-container {
    display: flex;
    gap: 2rem;
    padding: 3rem 0 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  footer .main-footer-container {
    display: flex;
    gap: 2rem;
    padding: 3rem 0 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .links-Container {
    flex-wrap: wrap;
    gap: 2rem;
  }
  .links-Container {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
