.booking-view {
  background-color: var(--bg-primary);
  margin: 0px calc(0px - var(--padding-inline));
  padding: 40px var(--padding-inline);
  margin-bottom: calc(0px - 32px);
  margin-inline: calc(0px - var(--padding-inline));
  scroll-padding-inline-start: 'var(--padding-inline)';
}

.booking-view .title-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 1rem;
}
.booking-view .title-container h1 {
  /* margin-top: 4px; */
}

.booking-view .status-action-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.booking-view .status-action-container .status {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: var(--clr-primary);
  font-weight: 600;
  background-color: var(--bg-blue);
}

.booking-view .status-action-container .status.pending {
  background-color: #fffbe7;
}

.booking-view .status-action-container .status.cancelled {
  background-color: #f6e7e7;
}

.booking-view .status-action-container .status > div {
  display: flex;
  font-size: 20px;
}

.booking-view .status-action-container .action-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.booking-details-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.booking-details-container > * {
  background-color: white;
  border-radius: 10px;
  padding: 24px;
  gap: 24px;
}

.booking-details-container > .booking-details {
  grid-row: 1/4;
}

.booking-details-container > .coupon-details {
  display: flex;
  gap: 16px;
}

.booking-details-container > .price-details {
  grid-column: 2/-1;
}

.booking-details-container > .photo-details {
  grid-column: 2/-1;
  /* grid-row: 3/5; */
  grid-row: 2/5;
}

.booking-details-container > .photo-details > div {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1.5rem 0rem;
}

.booking-details-container > .photo-details > p {
  font-weight: 500;
  font-size: 18px;
  color: var(--clr-black);
}

.booking-details-container > .button-container {
  order: 4;
  background-color: transparent;
  padding: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.booking-details-container button {
  min-width: 120px;
}

.booking-details-container h2 {
  font-size: 22px;
  font-weight: 600;
}

.booking-details-container .grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid > p {
  color: var(--clr-gray);
}

.grid > div {
  color: var(--clr-black);
  font-weight: 500;
}

.grid > hr {
  grid-column: 1/ -1;
  width: 100%;
  border: 2px solid #f2f7ff;
  margin: 0px;
}

.price-details .grid {
  grid-template-columns: 1fr auto;
}

.download-details > div {
  font-weight: 500;
  font-size: 16px;
}

.download-details > div > span {
  font-size: 15px;
  display: inline-flex;
  position: relative;
  top: 2px;
}

.booking-view .assign-team-container {
  margin-bottom: 1rem;
  padding: 24px;
}

.booking-view .assign-team-container .form-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 24px;
}

.booking-view .assign-team-container .form-wrapper .field-container {
  flex-basis: 300px;
}

.booking-view .assign-team-container .form-wrapper .form-btn-container {
  flex-basis: 120px;
  margin-bottom: 19px;
}

.re-schedule-dialog button.Active {
  background-color: rgba(0, 40, 82, 0.1);
}

@media only screen and (max-width: 1024px) {
  .booking-details-container {
    grid-template-columns: 1fr;
  }

  .booking-details-container > .booking-details,
  .booking-details-container > .price-details,
  .booking-details-container > .download-details,
  .booking-details-container > .photo-details {
    grid-column: 1/-1;
    grid-row: unset;
  }
}

@media only screen and (max-width: 600px) {
  .booking-view {
    margin: 0rem -1rem;
    padding: 4rem 1rem;
  }

  .booking-details-container > .booking-details .grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .booking-details-container > .booking-details .grid > p {
    margin-top: 0.75rem;
  }
}
