.about-us-our-benefits-main-container {
  margin: 0 -4rem;
  margin-bottom: -32px;
}

.about-us-our-benefits-container {
  background-color: #f9f9f9;
  padding: 4rem;
  display: flex;
  gap: 5rem;
}

.about-us-our-benefits-title > h5 {
  font-size: 16px;
  color: var(--clr-secondary);
  font-weight: 500;
  padding-bottom: 1rem;
  position: sticky;
  top: 100px;
}

.about-us-our-benefits-title > p {
  color: var(--clr-primary);
  font-size: 32px;
  font-weight: 400;
  position: sticky;
  top: 140px;
}

.about-us-our-benefits-card-container {
  display: grid;
  grid-template-columns: repeat(2, 0.7fr);
  gap: 3rem;
  padding-bottom: 5rem;
  /* padding-left: 5rem; */
}

.about-us-our-benefits-main-container .our-benefits-card {
  background-color: var(--bg-secondary);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #00000014;
  border-radius: 8px;
  transition: all 400ms 1000ms;
}

.about-us-our-benefits-main-container .our-benefits-card,
.about-us-our-benefits-main-container .our-benefits-card .our-benefits-title,
.about-us-our-benefits-main-container .our-benefits-card .our-benefits-subtitle,
.about-us-our-benefits-main-container .our-benefits-card .hoverable {
  transition: all 350ms;
}

.about-us-our-benefits-main-container .our-benefits-card:nth-child(even) {
  margin-top: 3rem;
  margin-bottom: -3rem;
}

.about-us-our-benefits-main-container .our-benefits-card:hover {
  background-color: var(--clr-secondary);
}

.about-us-our-benefits-main-container .our-benefits-card:hover .our-benefits-title {
  color: var(--bg-secondary);
}

.about-us-our-benefits-main-container .our-benefits-card:hover .our-benefits-subtitle {
  color: var(--bg-secondary);
}

.about-us-our-benefits-main-container .our-benefits-card:hover .hoverable {
  --clr-primary: #002852;
  --clr-secondary: #fff;
}

@media only screen and (max-width: 1024px) {
  .about-us-our-benefits-container {
    flex-wrap: wrap;
  }

  .about-us-our-benefits-card-container {
    /* padding-top: 3rem; */
    padding-left: unset;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-our-benefits-card-container {
    gap: 3rem 2rem;
  }

  .about-us-our-benefits-main-container .our-benefits-card {
    background-color: unset;
    padding: unset;
    border: unset;
    border-radius: unset;
  }

  .about-us-our-benefits-main-container .our-benefits-card:nth-child(even) {
    margin-top: unset;
    margin-bottom: unset;
  }

  .about-us-our-benefits-main-container {
    margin: 0 -1.5rem;
  }

  .about-us-our-benefits-container {
    padding: 1.5rem;
  }
}
