.address-details .address-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.address-details .address-details-grid .address-card {
  background-color: var(--bg-blue);
  padding: 1rem;
}

.address-details .address-details-grid .address-card .address-header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.address-details .icon-edit {
  display: flex;
  background-color: #d4dbe287;
  padding: 8px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .address-details .address-details-grid {
    grid-template-columns: 1fr;
  }
}
