.MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 4px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  color: var(--clr-black);
  font-weight: 500;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.multiselect-option.Mui-focused,
.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.multiselect-option.Mui-focused[aria-selected='true'],
.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #ec272622;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'],
.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'].Mui-focused {
  background-color: var(--clr-secondary);
  color: white;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.in-active,
.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.in-active.Mui-focused {
  background-color: white;
  color: var(--clr-gray);
  cursor: default;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.multiselect-option[aria-selected='true'] {
  background-color: white;
  color: var(--clr-black);
}
