.warranty-page .text-desc {
  font-size: 1rem;
  line-height: 1.3;
  color: var(--clr-gray);
}

.warranty-page .text-title {
  font-size: 2rem;
  line-height: 1.1;
  color: var(--clr-primary);
}
