.FeedPumpDeteriorating-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 4rem;
  margin-block: 4rem;
}

.FeedPumpDeteriorating-container > * {
  flex-grow: 1;
}

.FeedPumpDeteriorating-container .title-text {
  flex-basis: 100%;
}

.FeedPumpDeteriorating-container .title-text p:nth-child(2) {
  max-width: 50%;
}

.FeedPumpDeteriorating-container > img {
  object-fit: cover;
  object-position: center;
  /* width: 100px; */
}

.FeedPumpDeteriorating-container .tips-container,
.FeedPumpDeteriorating-container > img {
  flex-basis: calc(50% - 3rem);
  width: calc(50% - 3rem);
  flex-grow: 1;
}

.FeedPumpDeteriorating-container .tips-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.FeedPumpDeteriorating-container .tip-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--clr-primary);
  color: white;
  padding: 1.5rem 15% 1.5rem 2rem;
}

.FeedPumpDeteriorating-container .tip-card h3 {
  color: white;
  font-weight: 500;
}

.FeedPumpDeteriorating-container .tip-card .text-desc {
  color: white;
  width: 80%;
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  .FeedPumpDeteriorating-container {
    flex-direction: column;
  }

  .FeedPumpDeteriorating-container .tips-container,
  .FeedPumpDeteriorating-container > img {
    flex-basis: 100%;
    width: 100%;
  }

  .FeedPumpDeteriorating-container .title-text p:nth-child(2) {
    max-width: unset;
  }
}
